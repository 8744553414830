import React from 'react'

const CapacityLandingIframe = ({
  path = process.env.GATSBY_CAPACITY_LANDING_URL
  // type
}) => (
  <iframe
    id="if1"
    title="capacity page"
    width="100%"
    style={{ height: '100vh', border: 'none' }}
    sandbox="allow-top-navigation allow-scripts allow-forms allow-same-origin allow-popups"
    // src={`${path}/${type}`}
    src={`${path}`}
  ></iframe>
)

export default CapacityLandingIframe
