//  libraries
import React from 'react'

//  components
import Layout from 'components/layout'
import SEO from 'components/seo'
import CapacityLandingIframe from '../components/CapacityLanding'

const Capacity = () => {
  return (
    <Layout>
      <SEO title="Capacity - Edge Logistics" />
      <CapacityLandingIframe />
    </Layout>
  )
}

export default Capacity
